export const options = {
  minLength: 5,
};

export function execute(value: string | unknown): string[] {
  const errorMessages = {
    INVALID: "INVALID",
  };
  const errors: string[] = [];

  if (typeof value !== "string") {
    errors.push(errorMessages.INVALID);
  } else {
    const atSymbolCount = (value.match(/@/g) || []).length;
    const multiplePeriods = /\.{2,}/.test(value);
    const lastAtSymbolIndex = value.lastIndexOf("@");
    const lastDotIndex = value.lastIndexOf(".");

    if (atSymbolCount !== 1) {
      errors.push(errorMessages.INVALID);
    }

    if (multiplePeriods) {
      errors.push(errorMessages.INVALID);
    }

    if (lastAtSymbolIndex < 1 || lastAtSymbolIndex === value.length - 1) {
      errors.push(errorMessages.INVALID);
    }

    if (
      lastDotIndex < lastAtSymbolIndex + 2 ||
      lastDotIndex === value.length - 1
    ) {
      errors.push(errorMessages.INVALID);
    }
  }

  return errors.filter((value, index, array) => array.indexOf(value) === index);
}

export function isValid(value: string | unknown): boolean {
  return execute(value).length === 0;
}
