import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultError from "../../../DefaultError";

interface CollectUserQueryErrorProps {
  onTryAgain: Function;
  onGoBackHome: Function;
}

export default function CollectUserQueryError({
  onTryAgain,
  onGoBackHome,
}: CollectUserQueryErrorProps) {
  const { t } = useTranslation<any>();

  function onTryAgainButtonClick() {
    onTryAgain();
  }

  function onGoBackHomeClick() {
    onGoBackHome();
  }

  return (
    <DefaultError>
      <Stack textAlign="left" spacing={4}>
        <Typography pt={10} variant="h1" color="inherit">
          {t("CollectUserQueryError.title")}
        </Typography>
        <Stack spacing={2}>
          <Typography variant="body1" color="inherit">
            {t("CollectUserQueryError.body")}
          </Typography>

          <Typography variant="body1" fontWeight="900" color="primary">
            {t("common.helpEmailAddress")}
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Button
            onClick={onGoBackHomeClick}
            color="primary"
            variant="outlined"
            fullWidth
          >
            {t("CollectUserQueryError.goHomeLabel")}
          </Button>
          <Button onClick={onTryAgainButtonClick} color="primary" fullWidth>
            {t("CollectUserQueryError.tryAgainButtonLabel")}
          </Button>
        </Stack>
      </Stack>
    </DefaultError>
  );
}
