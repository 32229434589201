import { Stack } from "@mui/material";
import CollectUserQuerySuccess from ".";

export default function CollectUserQuerySuccessExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <CollectUserQuerySuccess
        onGoHome={() => console.log("onGoHome click")}
        queryLoggerFirstName="Lesedi"
        onLogAnotherQuery={() => console.log("onLogAnotherQuery click")}
      />
    </Stack>
  );
}
