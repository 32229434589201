import { Stack } from "@mui/material";
import CollectUserQueryError from ".";

export default function CollectUserQueryErrorExamples() {
  function onTryAgain() {
    console.log("onTryAgain");
  }
  function onContinue() {
    console.log("onContinue");
  }

  return (
    <Stack sx={{ height: "100vh" }}>
      <CollectUserQueryError
        onGoBackHome={onContinue}
        onTryAgain={onTryAgain}
      />
    </Stack>
  );
}
