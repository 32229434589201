import { Box, Typography } from "@mui/material";
import CollectUserQueryForm from ".";

export default function CollectUserQueryFormExamples() {
  function onSubmit() {
    console.log("Submitted");
  }
  const existingUserDetails =
    '{"userDetails": {"firstName":"Lesedi","lastName":"Persona", "phoneNumber":{ "countryCode": "ZA", "globalSubscriberNumber": "+27123456789" },"emailAddress":"lesedi@persona.com","feedbackMessage":"This is a feedback message"}}';
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography>Logged out</Typography>
        <CollectUserQueryForm onSubmit={onSubmit} />

        <Typography>Logged in</Typography>
        <CollectUserQueryForm
          onSubmit={onSubmit}
          data={JSON.parse(existingUserDetails)}
        />
      </Box>
    </>
  );
}
